<style scoped>
	.content {
		width: 100%;
		height: 637px;
		min-width: 1320px;
	}
	.con {
		width: 1320px;
		height: 447px;
		box-shadow: 0 0 40px #DADADA;
		margin: 0 auto;
		border-radius: 10px;
	}
	.con > img {
		width: 625px;
		height: 100%;
		border-radius: 0 10px 10px 0;
	}
	.con-left {
		flex: 1;
		padding: 30px 60px 0px;
	}
	.con-p1 {
		color: #222;
		font-size: 33px;
		letter-spacing: 3px;
		font-weight: 550;
	}
	.con-div {
		background: #75B9F6;
		width: 64px;
		height: 4px;
		margin: 24px 0 45px;
	}
	.con-p2 {
		margin-bottom: 22px;
		font-size: 18px;
		color: #666;
	}
	.con-div2 > img {
		width: 129px;
		height: 129px;
		margin-right: 42px;
	}
	/*  */
	.content2 {
		min-width: 1320px;
		background: #000;
	}
	.con2 {
		width: 1320px;
		height: 100%;
		margin: 0 auto;
		padding: 115px 0;

		color: #fff;
	}
	.con2-p1 {
		font-size: 17px;
		text-align: center;
	}
	.con2-p2 {
		font-size: 29px;
		color: #75B9F6;
		margin: 39px 0;
		text-align: center;
	}
	.inner {
		margin-right: 25px;
		font-size: 15px;
	}
	.inner > img {
		width: 18px;
		height: 18px;
		margin-right: 20px;
	}
	.con2-p3 {
		font-size: 22px;
		text-align: center;
		margin: 103px 0 37px;
	}
	.con2-div {
		width: 556px;
		height: 65px;
		border: 1px solid #fff;
		margin: auto;
		justify-content: flex-end;
	}
	.con2-div > input {
		flex: 1;
		height: 60px;
		background-color: #4A4B4E;
		padding: 0 20px;
		color: #fff;
		font-size: 18px;
		letter-spacing: 3px;
	}
	.con2-div > div {
		height: 63px;
		line-height: 63px;
		width: 166px;
		background: #75B9F6;
		text-align: center;
		font-size: 20px;
		letter-spacing: 5px;
	}
</style>

<template>
	<div>
		<Top :topTitle="topTitle"></Top>
		<div class="content flex-y-center">
			<div class="con flex">
				<div class="con-left">
					<p class="con-p1">联系我们</p>
					<div class="con-div"></div>
					<p class="con-p2">电话：{{info.mobile}}</p>
					<p class="con-p2">邮箱：{{info.email}}</p>
					<p class="con-p2">地址：{{info.address}}</p>
					<div class="con-div2 flex">
						<img :src="item" alt=""  v-for="(item, index) in info.wechat_code" :key="index" >
					</div>
				</div>
				<img :src="info.contact_image" alt="" v-if="info.contact_image">
				<img src="../assets/images/contact-1.png" alt="" v-else>
			</div>
		</div>
		<div class="content2">
			<div class="con2">
				<!-- <p class="con2-p1">可以联系我们在下方留下你们的联系方式</p> -->
				<p class="con2-p2">我们的联系电话：{{info.mobile}}</p>
				<div class="email-www flex-x-center">
					<div class="inner flex-y-center">
						<img src="../assets/images/contact-2.png" alt="">
						<p>邮箱：{{info.email}}</p>
					</div>
					<div class="inner flex-y-center">
						<img src="../assets/images/contact-3.png" alt="">
						<p>网址：{{info.url}}</p>
					</div>
				</div>
				<!-- <p class="con2-p3">留下你的联系方式</p>
				<div class="con2-div flex">
					<input type="text" placeholder="你的联系方式">
					<div>提交</div>
				</div> -->
			</div>
		</div>
		<Bottom :minWidth="minWidth"></Bottom>
	</div>
</template>

<script>
	import Top from "../../components/top.vue";
	import Bottom from "../../components/bottom.vue";
	export default {
		name: "",
		data() {
			return {
				topTitle: "联系我们",
				minWidth: 1320,
				info: JSON.parse(sessionStorage.getItem("topBottomInfo"))
			}
		},
		components: {
			Top, Bottom
		}
	}
</script>

